<div class="calendar-container">
  <div class="relative">
    <input pInputText class="h-9 min-h-0 min-w-[300px] truncate" placeholder="Select date" type="text"
      (click)="filterOP.toggle($event); setTranslateCalendar()" readonly [value]="dateString" />
    <i [class]="
        SoctripIcons.CALENDAR +
        ' absolute text-palette-gray-500 right-3 top-1/2 -translate-y-1/2 text-lg cursor-pointer'
      " (click)="filterOP.toggle($event); setTranslateCalendar()"></i>
  </div>

  <p-overlayPanel #filterOP>
    <div class="flex h-full gap-4 p-3" (click)="$event.stopPropagation()">
      <div class="mt-0.5 grid h-full gap-2">
        <label *ngFor="let item of DF" class="cursor-pointer py-1 text-sm font-medium" [ngClass]="
            DFActive === item.value
              ? 'text-palette-blue-600'
              : 'hover:text-palette-blue-500'
          " (click)="filterDate(item)">
          {{ "primeng." + item.value | translate | sentenceCase }}
        </label>
      </div>
      <div class="flex h-full flex-col gap-4 calendar-container">
        <p-calendar ngClass="min-w-[15rem] border-none" [maxDate]="currentDate" [(ngModel)]="rangeDates"  [firstDayOfWeek]="1" class="calendar-dashboard"
          (ngModelChange)="getDateFilterArray($event)" selectionMode="range" [numberOfMonths]="2" [showIcon]="true"
          [inline]="true"></p-calendar>
        <div class="flex justify-between gap-2 calendar-container">
          <span class="flex items-center gap-2 pointer-events-none">
            <p-calendar [(ngModel)]="dateFrom" (change)="setDateFilter($event)" ngClass="w-[10rem] calendar-container"
              [showOnFocus]="false" dateFormat="dd/mm/yy"></p-calendar>
            <span>-</span>
            <p-calendar [(ngModel)]="dateTo" (change)="setDateFilter($event)" ngClass="w-[10rem]" [showOnFocus]="false"
              dateFormat="dd/mm/yy"></p-calendar>
          </span>
          <span class="flex gap-2">
            <button
              class="!h-11 !w-[88px] rounded-lg border bg-white !px-5 !py-3 font-inter !text-sm font-semibold text-palette-gray-900"
              (click)="filterOP.toggle($event)">
              {{ "COMMON.CANCEL" | translate }}
            </button>
            <button
              class="!h-11 !min-w-[88px] rounded-lg border bg-palette-blue-600 !px-5 !py-3 font-inter !text-sm font-semibold text-white"
              [disabled]="
                (!rangeDates[0] && rangeDates[1]) ||
                (rangeDates[0] && !rangeDates[1])
              " (click)="
                rangeDatesChange.emit(rangeDates);
                onApply.emit( { type: this.DFActive, isSpecial: this.isSpecial});
                filterOP.toggle($event)
              ">
              {{ "COMMON.APPLY" | translate }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </p-overlayPanel>
</div>