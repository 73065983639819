import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';

@Component({
    selector: 'app-suggest-search',
    templateUrl: './suggest-search.component.html',
    styleUrls: ['./suggest-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SuggestSearchComponent),
            multi: true
        }
    ]
})
export class SuggestSearchComponent implements ControlValueAccessor {
    @ViewChild('input', { static: false }) input!: ElementRef | null;

    @Input() icon: string = '';
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() class: string = '';
    @Input() ngClass: any = '';
    @Input() formControlName: string = '';
    @Input() offsetWidth?: number;
    @Input() offsetHeight?: number;
    @Output() onInputEmit = new EventEmitter();
    @Output() onFocusEmit = new EventEmitter();
    @Output() onBlurEmit = new EventEmitter();

    isShowSuggestions: boolean = false;
    value: any = '';
    dropdownPosition: 'below' | 'above' = 'below';
    dropdownAlign: 'left' | 'right' = 'left';

    onChange: (value: any) => void = () => { };
    onTouched: () => void = () => { };

    ngAfterViewInit() {
        setTimeout(() => {
            this.adjustDropdownPosition();
        }, 100);
    }

    // Getter and Setter for the value
    get control(): FormControl {
        return this._control;
    }

    set control(val: FormControl) {
        this._control = val;
        this.value = val.value;
    }

    private _control: FormControl = new FormControl();

    writeValue(value: any): void {
        this.value = value;
        if (this._control) {
            this._control.setValue(value, { emitEvent: false });
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // Handle disable logic if needed
    }

    // Function to call when input changes
    onInputChange(event: any) {
        this.value = event.target.value;
        this.onChange(this.value);
        this.onInputEmit.emit();
    }

    onFocus() {
        this.isShowSuggestions = true;
        this.onFocusEmit.emit();
    }

    onBlur() {
        this.onTouched();
        this.isShowSuggestions = false;
        this.onBlurEmit.emit();
    }

    @HostListener('window:resize')
    onResize() {
        this.adjustDropdownPosition();
    }

    adjustDropdownPosition() {
        if (!this.input) {
            return; // Ensure both dropdown and input elements exist
        }

        const inputEl = this.input.nativeElement;
        const inputRect = inputEl.getBoundingClientRect();

        // Check if there is enough space below the input for the dropdown
        const offsetHeight = this.offsetHeight ?? 0;
        if (window.innerHeight - inputRect.bottom > offsetHeight) {
            this.dropdownPosition = 'below';
        } else {
            this.dropdownPosition = 'above';
        }

        // Check if there is enough space on the left for the dropdown
        const offsetWidth = this.offsetWidth ?? 0;
        if (window.innerWidth - inputRect.left > offsetWidth) {
            this.dropdownAlign = 'left';
        } else {
            this.dropdownAlign = 'right';
        }
    }
}
