import { Component, Input } from '@angular/core';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
@Component({
  selector: 'app-price-format',
  templateUrl: './price-format.component.html',
  styleUrls: ['./price-format.component.scss'],
})
export class PriceFormatComponent {
  @Input() price: number;
  @Input() styleClass: string;
  currentCurrency: any;

  constructor() {}
  ngOnInit(): void {}

  formatPrice(price: number): string {
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
    return formattedPrice;
  }
}
