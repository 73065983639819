<div #calendarDashboardContainer class="calendar-dashboard-container text-cursor">
    <span class="w-full p-input-icon-right text-cursor" (click)="calendarDashBoard.toggle($event)">
        <input pInputText #inputText class="h-9 min-h-0 truncate text-cursor" placeholder="Select date" type="text"
            readonly [value]="dateString" />
        <i class="sctr-icon-calendar "></i>
    </span>
    <p-overlayPanel #calendarDashBoard (onHide)="outFocus()" (onShow)="onFocus()" styleClass="calendar-overlayPanel">
        <ng-template pTemplate="body">
            <div class="flex max-w-[460px] gap-4 px-2">
                <div class="my-2 gap-2">
                    <ul class="flex flex-col">
                        <li class="cursor-pointer truncate rounded p-2 text-sm font-medium hover:bg-palette-gray-100"
                            *ngFor="let item of DF1"
                            [ngClass]="
                                DFActive === item.value
                                ? 'bg-palette-blue-50 text-palette-blue-600 '
                                : 'hover:text-palette-blue-500'
                            "
                            (click)="filterDate(item)">
                            {{ "primeng." + item.value | translate }}
                        </li>
                    </ul>
                    <hr />
                    <ul class="flex flex-col">
                        <li class="flex cursor-pointer items-center justify-between truncate rounded p-2 text-sm font-medium hover:bg-palette-gray-100"
                            *ngFor="let item of DF2"
                            [ngClass]="
                                DFActive === item.value
                                ? 'bg-palette-blue-50 text-palette-blue-600'
                                : 'hover:text-palette-blue-500'
                            "
                            (click)="filterDate(item)">
                                {{ "COMMON.BY" | translate }}
                                {{ "primeng." + item.value | translate }}
                            <i class="sctr-icon-chevron-right"></i>
                        </li>
                    </ul>
                </div>
                <div class="test-cursor border-l">
                    <p-calendar 
                        #calendar
                        class="calendar-dashboard custom-calendar text-cursor"
                        panelStyleClass="!border-0 w-full h-full"
                        [selectionMode]="selectionMode"
                        [view]="viewDate"
                        [firstDayOfWeek]="1"
                        [maxDate]="currentDate"
                        [defaultDate]="currentDate"
                        [yearRange]="currentDate.getFullYear().toString()"
                        [(ngModel)]="rangeDates"
                        [inline]="true"
                        (ngModelChange)="onSelect($event)"
                        [showOnFocus]="false">
                    </p-calendar>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>