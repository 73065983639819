import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderComponent } from '@layout/header/header.component';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { PermissionService } from './core/services/permission/permission.service';
import { ButtonModule } from 'primeng/button';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { SharedModule } from '@shared/shared.module';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { ToastModule } from 'primeng/toast';
import { SoctripTranslationService } from './services/soctrip-translation/soctrip-translation.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    /* primeng */
    ButtonModule,
    /* @angular */
    RouterOutlet,
    /* @components */
    FooterComponent,
    HeaderComponent,
    SharedModule,
    ToastModule,
  ],
  template: `
    <!-- <app-header /> -->
    <div *ngIf="!isLoading">
      <router-outlet></router-outlet>
      <app-toast></app-toast>
    </div>
  `,
})
export class AppComponent {
  //TODO: Replace with actual code
  loginForm = { password: '123456a@A', username: 'testaccount' };
  userRole: string[] = [];
  socialApp: string = environment.ADMIN_APP_URL;
  socialAppConnectUrl: string = environment.ADMIN_APP_URL + '/app-connect';
  socialAppSafeUrl: SafeResourceUrl;
  public sanitizer: DomSanitizer;
  isLoading: boolean = true;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private permissionService: PermissionService,
    private translateService: TranslateService,
    sanitizer: DomSanitizer,
    private cdref: ChangeDetectorRef,
    private soctripTranslationService: SoctripTranslationService
  ) {
    this.translateService.addLangs(environment.support_languages);
    this.userService
      .getUserProfile()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user) {
          if (
            user.language &&
            environment.support_languages.includes(user.language.toLowerCase())
          ) {
            const lang = user.language.toLowerCase();
            this.soctripTranslationService.setLanguage(lang)
            this.cdref.detectChanges();
          } else {
            this.translateService.setDefaultLang(
              environment.support_languages[0]
            );
            localStorage.setItem('lang', environment.support_languages[0]);
          }
        } else {
          localStorage.setItem('lang', environment.support_languages[0]);
          // localStorage.setItem('currency', environment.support_currency[0]);
          this.translateService.setDefaultLang(
            environment.support_languages[0]
          );
        }
      });

  
  }

  ngAfterViewInit(): void {
    localStorage.setItem('ignore-secure', '');
    this.connectPMServer();
  }

  

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.socialAppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'connect' }, '*');
    };
    ifr.onload = connect;
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    if (window.location.hostname !== 'localhost') {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
      if (
        event.origin === this.socialApp &&
        (event.data.type === 'authorized' || event.data.type === 'unauthorized')
      ) {
        const accessToken = event.data?.token?.accessToken;
        const profile = JSON.parse(event.data?.profile);
        if (accessToken && profile) {
          this.socialAppConnectUrl = '';
          this.isLoading = false;
          this.tokenStorageService.saveToken(accessToken);
          localStorage.setItem(
            environment.USER_ROLE_KEY,
            JSON.stringify(['USER'])
          );
          const user = JSON.parse(
            localStorage.getItem(environment.USER_PROFILE) || '{}'
          );
          localStorage.setItem(
            environment.USER_PROFILE,
            JSON.stringify(profile)
          );
          localStorage.setItem(
            'auth_status',
            JSON.stringify({ isLoggedIn: true })
          );
          this.userService.setUserInfo(profile);
          if (profile?.id != user.id) {
            this.userService.getUserInfoById(profile?.id).subscribe((data) => {
              if (data) {
                this.userService.setUserInfo(data);
                this.userService.setUserProfile(data);
                localStorage.setItem(
                  environment.USER_PROFILE,
                  JSON.stringify(data)
                );
                this.userService.setLoginStatus(true);
                localStorage.setItem(
                  'auth_status',
                  JSON.stringify({ isLoggedIn: true })
                );
              }
            });
          }
          this.userService.setLoginStatus(true);
        } else {
          localStorage.removeItem(environment.AUTH_STATUS);
          localStorage.removeItem(environment.TOKEN_KEY);
          localStorage.removeItem(environment.USER_PROFILE);
          localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
          localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
          localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
          localStorage.removeItem(environment.USER_ROLE_KEY);
          this.userService.setUserProfile(null);
          this.userService.setUserInfo(null);
          this.userService.setLoginStatus(false);
          // window.location.href = environment.ADMIN_APP_URL + '/login';
        }
      }
    } else {
      this.isLoading = false;
      this.userService.setUserProfile(null);
    }
  }

  login() {
    this.authService.login(this.loginForm).subscribe((res: any) => {
      this.tokenStorageService.saveToken(res.accessToken);
      this.tokenStorageService.saveRefreshToken(res.refreshToken);
      this.tokenStorageService.saveAccessTokenExpiry(
        //TODO: Replace with actual code
        // res.access_token_expiry_date
        '2023-07-17 10:14:55'
      );
      this.tokenStorageService.saveRefreshTokenExpiry(
        //TODO: Replace with actual code
        // res.refresh_token_expiry_date
        '2023-07-30 17:50:55'
      );

      //TODO: Replace with actual code
      // userID (temporary)
      const userId = '123456';
      this.userService.getUserInfo(userId).subscribe((res: any) => {
        //TODO: Replace with actual code
        this.userRole = res;
        this.userService.saveUserInfoToLocalStorage(this.userRole);
      });
      this.permissionService.userRoles.next(this.userRole);
      this.router.navigate(['/users']);
    });
  }

  logout() {
    this.tokenStorageService.clearTokenLocalStorage();
    this.userService.clearUserLocalStorage();
    this.permissionService.userRoles.next(['']);

    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  //TODO: Replace with actual code
  getUserInfo() {
    this.authService.getUserInfo().subscribe();
    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
